import React from 'react';
import { Box, Heading, Text, VStack, Container, useColorModeValue } from '@chakra-ui/react';

export const Story: React.FC = () => {
  const bgColor = useColorModeValue('rgba(0, 0, 0, 0.7)', 'rgba(0, 0, 0, 0.8)');
  const textColor = useColorModeValue('white', 'gray.100');

  return (
    <Box
      as="section"
      py={16}
      position="relative"
      backgroundImage="url('/api/placeholder/1500/800')"  // Replace with actual image URL
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundAttachment="fixed"
      width="100%"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg={bgColor}
        zIndex={0}
      />
      <Container maxW="container.xl" position="relative" zIndex={1} px={{ base: 4, sm: 6, lg: 8 }}>
        <VStack align={{ base: "center", md: "start" }} spacing={6} color={textColor}>
          <Heading as="h2" size="3xl" mb={4} textAlign={{ base: "center", md: "left" }}>
            Our Story
          </Heading>
          <Text fontSize="xl" textAlign={{ base: "center", md: "left" }}>
            YRI emerged from the trenches of corporate inefficiency. Our founders experienced firsthand the pitfalls of poor strategy and execution in Fortune 500 companies. We've crafted an AI-driven solution that bridges the gap between vision and reality, ensuring strategies are both achievable and effectively executed.
          </Text>
          <Text fontSize="xl" fontWeight="bold" textAlign={{ base: "center", md: "left" }}>
            We discovered a critical insight: strategies were failing before execution even began - misaligned with capabilities, built on unrealistic expectations, and disconnected from ground realities.
          </Text>
          <Text fontSize="xl" textAlign={{ base: "center", md: "left" }}>
            Success demands both well-designed strategies AND effective execution - an integrated approach powered by AI.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};
