import React from 'react';
import { Box, Heading, Text, SimpleGrid, VStack, useColorModeValue, Container } from '@chakra-ui/react';

interface FeatureProps {
  title: string;
  description: string;
}

const Feature: React.FC<FeatureProps> = ({ title, description }) => {
  return (
    <VStack align="start" spacing={3}>
      <Heading as="h3" size="md">
        {title}
      </Heading>
      <Text>{description}</Text>
    </VStack>
  );
};

export const Overview: React.FC = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.700');

  return (
    <Box as="section" py={16} bg={bgColor} width="100%">
      <Container maxW="container.xl" px={{ base: 4, sm: 6, lg: 8 }}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
          <Feature
            title="Core Strategy AI"
            description="Challenge, Verify or Create your strategy with the help AI to understand what is possible and what is probable."
          />
          <Feature
            title="Strategy Testing and Simulation"
            description="Stress-test your strategies against real-world scenarios to validate their viability and identify potential risks."
          />
          <Feature
            title="Document Collection and Processing"
            description="Leverage our natural language processing to extract valuable insights from your internal and external data sources."
          />
          <Feature
            title="AI Interviewer"
            description="Our AI interviewing system to get the real state of the business using existing communication channels. All at once."
          />
        </SimpleGrid>
      </Container>
    </Box>
  );
};
