import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';

export const Contact: React.FC = () => {
  return (
    <Box id="contact" maxW="900px" mx="auto" p={6} borderRadius="md">
      <form action="https://getform.io/f/alllzlqa" method="POST">
        {/* Name Field */}
        <FormControl id="name" isRequired mb={4}>
          <FormLabel>Name</FormLabel>
          <Input type="text" name="name" placeholder="Your Name" size="lg" />
        </FormControl>

        {/* Email Field */}
        <FormControl id="email" isRequired mb={4}>
          <FormLabel>Email</FormLabel>
          <Input type="email" name="email" placeholder="your@example.com" />
        </FormControl>

        {/* Message Field */}
        <FormControl id="message" isRequired mb={4}>
          <FormLabel>Message</FormLabel>
          <Textarea name="message" placeholder="Your message..." />
        </FormControl>

        {/* Honeypot Field */}
        <Input type="hidden" name="_gotcha" />

        {/* Submit Button */}
        <Button type="submit" colorScheme="blue" width="full" size="lg">
          Send
        </Button>
      </form>
    </Box>
  );
};
