import React from 'react';
import { ChakraProvider, Box, VStack } from '@chakra-ui/react';
import { Header } from './components/header';
import { Hero } from './components/hero';
import { Overview } from './components/overview';
import { Story } from './components/story';
import { What } from './components/what';
import { Threads } from './components/threads';
import { How } from './components/how';
import { Contact } from './components/contact';

function App() {
  return (
    <ChakraProvider>
      <Box>
        <Header />
        <VStack spacing={0} pt="80px"> {/* Add padding top to account for fixed header */}
          <Hero />
          <Overview />
          <Story />
          <What />
          <Contact />
        </VStack>
      </Box>
    </ChakraProvider>
  );
}

export default App;
